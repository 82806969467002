@font-face {
  font-family: "Gelion Bold";
  src: url("./assets/fonts/gelion-cufonfonts/Gelion\ Bold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gelion Regular";
  src: url("./assets/fonts/gelion-cufonfonts/Gelion\ Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Boldenvan";
  src: url("./assets/fonts/boldenvan/BoldenVan.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "General Sans";
  src: url(./assets/fonts/OTF/GeneralSans-Regular.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: "General Sans";
	src: url(./assets/fonts/OTF/GeneralSans-Medium.otf);
	font-weight: 500;
	font-style: normal;
}

@font-face {
  font-family: "General Sans";
  src: url(./assets/fonts/OTF/GeneralSans-Bold.otf);
  font-weight: bold;
  font-style: normal;
}
@font-face {
	font-family: "General Sans";
	src: url(./assets/fonts/OTF/GeneralSans-Italic.otf);
	font-weight: normal;
	font-style: italic;
}


:root {
  --primary-main: #ee2a7b;
  --primary-dark: #c62367;
  --primary-light: #fcd4e5;
  --accent_1-main: #fc94ff;
  --accent_1-dark: #d27bd4;
  --accent_1-light: #fdb8ff;
  --accent_2-main: #fff4f9;
  --accent_2-dark: #d4cbcf;
  --accent_2-light: #fffdfe;
  --accent_3-main: #020162;
  --accent_3-dark: #010141;
  --accent_3-light: #e1e8f2;
  --success-default: #005123;
  --success-light: #f2fffb;
  --error-default: #ab0001;
  --error-light: #fff3f8;
  --title-active: #14142b;
  --gray-scale: #3d3d3d;
  --warning-default: #755118;
  --warning-light: #FEF7D1;
  --info-default: #0C2A75;
  --info-light: #D7F1FD;
  --body: #6f7273;
  --label: #2E2E3A;
  --placeholder:  #2E2E3A;
  --line: #d5d8df;
  --input-bg: #F7F7F7;
  --background: #f7f7fc;
  --off-white: #fcfcfc;
  --filter-white: brightness(0%) saturate(100%) invert(100%) sepia(9%) saturate(2%) hue-rotate(284deg) brightness(113%) contrast(100%);
  --shadow_1: 0px 25px 35px 0px #00000008;
  --shadow_2: 0px 2px 5px 0px #0000000d;
  --font_1-regular: "Gelion Regular";
  --font_1-bold: "Gelion Bold";
  --font_2: "Boldenvan";
  --font_3-regular: "General Sans"; 
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  font-size: 10px;
}

body {
  color: var(--body);
  margin: 0;
  font-family: "General Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 475px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  font-size: inherit;
}

input {
  color: inherit;
  font-family: inherit;
}

input:focus {
  outline: none;
}

input:-internal-autofill-selected,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent !important;
}

button,
a {
  border: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
}

input:focus,
button:focus,
select:focus,
textarea:focus {
  outline: none;
}

select {
  font-family: inherit;
  appearance: none;
}

textarea {
  font-family: inherit;
  resize: none;
}

/* Title */

.title-1 {
  font-family: "Boldenvan";
  font-size: 56px;
  font-style: normal;
  font-weight: 475;
  line-height: 60px;
  letter-spacing: 0px;
}

.title-2 {
  font-family: "General Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 475;
  line-height: 56px;
  letter-spacing: 0px;
}

.title-3 {
  font-family: "General Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 475;
  line-height: 48px;
  letter-spacing: 0px;
}

.title-4 {
  font-family: "General Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 475;
  line-height: 36px;
  letter-spacing: 0px;
}

/* Subtitles */

.subtitle-1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 475;
  line-height: 30px;
  letter-spacing: 0px;
}

.subtitle-2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 475;
  line-height: 28px;
  letter-spacing: 0px;
}

.subtitle-3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 475;
  line-height: 28px;
  letter-spacing: 0px;
}

.subtitle-4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 475;
  line-height: 24px;
  letter-spacing: 0px;
}

.subtitle-5 {
  font-size: 14px;
  font-style: normal;
  font-weight: 475;
  line-height: 18px;
  letter-spacing: 0px;
}

/* Body */

.body-1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 475;
  line-height: 28px;
  letter-spacing: 0px;
}

.body-2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 475;
  line-height: 28px;
  letter-spacing: 0px;
}

.body-3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 475;
  line-height: 24px;
  letter-spacing: 0px;
}

.body-4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 475;
  line-height: 18px;
  letter-spacing: 0px;
}

.body-5 {
  font-size: 12px;
  font-style: normal;
  font-weight: 475;
  line-height: 18px;
  letter-spacing: 0px;
}
.body-lg-semibold {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
/* Meta */

.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 475;
  line-height: 18px;
  letter-spacing: 0px;
}

.metadata {
  font-size: 12px;
  font-style: normal;
  font-weight: 475;
  line-height: 16px;
  letter-spacing: 0px;
}

/* Utilities */

.fullWidth {
  width: 100%;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.alignStart {
  align-items: flex-start;
}

.alignSpaceBetween {
  align-items: space-between;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyStart {
  justify-content: flex-start;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.justifySpaceAround {
  justify-content: space-around;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.textUppercase {
  text-transform: uppercase;
}

.textCapitalize {
  text-transform: capitalize;
}

.cursorPointer {
  cursor: pointer;
}

.circle {
  border-radius: 50%;
}

.upperCase {
  text-transform: uppercase;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.noTextWrap {
  white-space: nowrap;
}

.stickyTop {
  position: sticky;
  top: 0;
}

.modalOpen {
  overflow: hidden;
}

.borderLight {
  border: 1px solid var(--line);
}

.ellipsify {
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hidden {
  display: none !important;
}

/* Colors */

.colorPrimaryMain {
  color: var(--primary-main);
}

.colorPrimaryDark {
  color: var(--primary-dark);
}

.colorBody {
  color: var(--body);
}

.colorTitleActive {
  color: var(--title-active);
}

.colorTextDescription {
  color: #8d9091;
}

.colorGrayScale {
  color: var(--gray-scale);
}

.colorWhite {
  color: #ffffff;
}

.colorErrorDefault {
  color: var(--error-default);
}

.colorAccent1Main {
  color: var(--accent_1-main);
}

.colorLink {
  color: #407bff;
}